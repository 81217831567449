import { PrismicNextLink } from "@prismicio/next";

export const Link = (props) => {
  console.log(props);
  if (Boolean(props.field?.isBroken)) return null;

  if (props.link_type === "Web" || props.url) {
    const { url, children, ...rest } = props;
    if (!props.url) return null;
    return (
      <a
        href={url}
        target={url?.includes("groundwork.nl") ? "_self" : "_blank"}
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (props.type === "coach_overview") {
    const { type, ...rest } = props;
    return <PrismicNextLink href="/coaches" {...rest} />;
  }

  return <PrismicNextLink linkResolver={linkResolver} {...props} />;
};

function linkResolver({ type, uid }) {
  if (type === "event") {
    return `/events/${uid}`;
  }

  if (type === "course_studyguide") {
    return `/opleidingen/${uid}/studiegids-aanvragen`;
  }

  if (type === "course_reviews") {
    return `/opleidingen/${uid}/reviews`;
  }

  if (type === "course_reviews_form") {
    return `/opleidingen/${uid}/reviews/formulier`;
  }

  if (type === "training_reviews") {
    return `/trainingen/${uid}/reviews`;
  }

  if (type === "training_reviews_form") {
    return `/trainingen/${uid}/reviews/formulier`;
  }

  if (type === "training_detail") {
    return `/trainingen/${uid}`;
  }

  if (type === "link") {
    return `/${uid}`;
  }

  return null;
}
